
import { Component, Vue, Watch } from "vue-property-decorator";

import StartGroupService from "../../services/sportevent/StartGroupService";
import StartGroup from "../../models/sportevent/StartGroup";
import DisciplineCategory from "../../models/sporteventCalculation/DisciplineCategory";

import ScoringComponent from "./Scoring.vue";
import PrintSettingsService from "../../services/helper/PrintSettingsService";

@Component({
  components: {
    ScoringComponent,
  },
})
export default class ListScoringSheetComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public startGroups: StartGroup[] = [];

  getAll(): void {
    StartGroupService.getAllBySportevent(true).then((items: StartGroup[]) => {
      this.startGroups = items.map((startGroup) => new StartGroup(startGroup));
      this.startGroups.forEach((startGroup: StartGroup) => {
        startGroup.starters.sort((a, b) =>
          a.positionInStartgroup > b.positionInStartgroup ? 1 : -1
        );
      });
      this.startGroups.sort((a: StartGroup, b: StartGroup) =>
        a.StartGroupName > b.StartGroupName ? 1 : -1
      );
      this.loading = false;
    });
    PrintSettingsService.setHeader();
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }

  getDisciplineCategories(startGroup: StartGroup): DisciplineCategory[] {
    let disciplineCategories: DisciplineCategory[] = [];

    for (let i = 0; i < startGroup.sporteventCompetitions.length; i++) {
      for (
        let v = 0;
        v < startGroup.sporteventCompetitions[i].disciplines.length;
        v++
      ) {
        disciplineCategories.push(
          startGroup.sporteventCompetitions[i].disciplines[v].disciplineCategory
        );
      }
    }

    disciplineCategories = [
      ...new Map(
        disciplineCategories.map((item) => [item["id"], item])
      ).values(),
    ];

    disciplineCategories.sort((a, b) => (a.name > b.name ? 1 : -1));
    return disciplineCategories;
  }
}
